import React, { useMemo, useState } from "react"

import Search from "antd/es/input/Search"
import { SorterResult } from "antd/es/table/interface"
import { TableProps } from "antd/lib"

import { Modal, Select } from "antd"
import { useAllMixes } from "api/endpoints/mixes/mixes"
import { useAllProductTypes } from "api/endpoints/products-types/products-types"
import { useAllProducts, useCreateCopyProduct } from "api/endpoints/products/products"
import { ProductDto, ProductForm } from "api/model"
import { queryClient } from "app/queryClient"
import { BaseJournal } from "shared/BaseJournal"
import { defaultSortedInfo } from "shared/constants/sort"
import { exportTable } from "shared/utils/exportTable"
import { selectListResultOptions } from "shared/utils/selectListResult"

import { createTableColumns } from "./artifacts/createTableColumns"
import { ProductModal } from "./components/ProductModal"

export const Products = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [selectValues, setSelectValues] = useState<number[]>([])
  const [selectedTypeId, setSelectedTypeId] = useState<number>()
  const [initialData, setInitialData] = useState<Partial<ProductForm & { id: number }>>({})

  const id = initialData?.id
  const [sortedInfo, setSortedInfo] = useState<SorterResult<ProductDto>>(defaultSortedInfo)

  const {
    data: products,
    queryKey: productsQueryKey,
    isFetching: isProductsFetching,
  } = useAllProducts(
    {},
    {
      query: { select: selectListResultOptions },
    },
  )
  const { data: productTypes } = useAllProductTypes(
    {},
    { query: { select: selectListResultOptions } },
  )

  const { data: mixes } = useAllMixes({}, { query: { select: selectListResultOptions } })

  const copyProduct = useCreateCopyProduct()

  const onCopyClick = async (id: number) => {
    Modal.confirm({
      title: "Вы точно хотите создать дубликат продукта?",
      okText: "Да",
      okType: "primary",
      cancelText: "Нет",
      icon: null,
      onOk() {
        copyProduct
          .mutateAsync({ id })
          .then((res) => onEditClick(res.result))
          .then(() => queryClient.invalidateQueries({ queryKey: productsQueryKey }))
          .catch(console.error)
      },
    })
  }

  const onEditClick = (record: ProductDto) => {
    setInitialData({
      ...record,
      type: record.type.id,
      mix: record?.mix?.id,
    })
    setSelectedTypeId(record.type.id)
    setIsOpen(true)
  }

  const isLoading = copyProduct.isPending || isProductsFetching

  const columns = useMemo(() => {
    return createTableColumns({
      isLoading,
      onCopyClick,
      onEditClick,
      sortedInfo,
    })
  }, [sortedInfo, isLoading])

  const onChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value)
  }

  const productsData = useMemo(() => {
    if (!products) return []
    const data = products.map((item, index) => ({ ...item, mockId: index + 1 }))
    return data
      .filter((product) => {
        const isValuesFromSelectValues =
          selectValues.length > 0 ? selectValues.includes(product.type.id) : true
        return searchValue && isValuesFromSelectValues
          ? product.name.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1
          : isValuesFromSelectValues
      })
      .sort()
  }, [products, searchValue, selectValues])

  const onSelect = (value: number[]) => setSelectValues(value)

  const handleChange: TableProps<ProductDto>["onChange"] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<ProductDto>)
  }

  const handleExport = () => {
    if (!products) return

    const flattenData: Record<string, string | number | undefined>[] = products.map((item) => {
      return {
        id: item.id,
        Название: item.name,
        "МДЖ %": item.fatPercentage,
        "МДБ %": item.proteinPercentage,
        "Тип продукта": item.type.name,
      }
    })

    exportTable({ data: flattenData, fileName: "Готовые продукты" })
  }

  const productsTypesOptions = useMemo(() => {
    if (!productTypes) return []
    return productTypes.map((productsType) => {
      return { value: productsType.id, label: productsType.name }
    })
  }, [productTypes])

  return (
    <BaseJournal
      title='Готовые продукты'
      onAddClick={() => {
        setIsOpen(true)
      }}
      onExportClick={handleExport}
      data={products ?? []}
      table={{
        columns,
        onChange: handleChange,
        loading: isLoading,
        dataSource: productsData,
      }}
      filters={
        <>
          <Search style={{ minWidth: 350 }} onChange={onChange} placeholder='Поиск по названию' />
          <Select
            mode='multiple'
            allowClear
            style={{ minWidth: 350 }}
            onChange={onSelect}
            options={productsTypesOptions}
            placeholder='Выберите тип продукта'
          />
        </>
      }
      modals={
        <>
          <ProductModal
            clearInitialData={() => setInitialData({})}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            id={id}
            initialData={initialData}
            productTypes={productTypes}
            mixes={mixes}
            setSelectedTypeId={setSelectedTypeId}
            selectedTypeId={selectedTypeId}
          />
        </>
      }
    />
  )
}
